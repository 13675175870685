import React, { useRef, useState, useEffect } from 'react';
import 'styles/main.css';

import { animated, useSpring } from 'react-spring';
import { isAndroid, isIOS } from 'react-device-detect';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import { AFTSystemStatus, GlobalDataSystemStatus, OmnishieldSystemStatus } from 'managers/Health.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import { EndIndex } from 'structure/Layer.js';
import Cookies from 'js-cookie';
import DatePicker from 'views/DatePicker.js';
import Events from 'managers/Events.js';
import Login, { runLogin } from 'views/Login.js';
import Request from 'files/Request.js';
import Sheet from 'views/Sheet.js';
import { Subscriptions } from 'managers/Pricing.js';
import { SupportContantForm } from 'managers/Support.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';

const EventsManager = Events.new();

const panels = {
    health: [{
        key: 'aft_system_status',
        Component: AFTSystemStatus
    },{
        key: 'global_data_system_status',
        Component: GlobalDataSystemStatus
    },{
        key: 'omnishield_system_status',
        Component: OmnishieldSystemStatus
    }],
    pricing: [{
        key: 'subscriptions',
        Component: Subscriptions
    }]
}

const App = () => {

    const dealershipRef = useRef(null);
    const layersRef = useRef(null);
    const profileRef = useRef(null);
    const userRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [alerts, setAlerts] = useState([]);
    const [badgeAnimations, setBadgeAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        opacity: 0,
        transform: 'scale(0.5)'
    }));
    const [contentAnimations, setContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 1,
        top: 0,
    }));
    const [datePicker, setDatePicker] = useState(null);
    const [dealership, setDealership] = useState(null);
    const [layers, setLayers] = useState([]);
    const [layerIndex, setLayerIndex] = useState([]);
    const [loader, setLoader] = useState(null);
    const [logoAnimations, setLogoAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        left: -50,
        opacity: 0
    }));
    const [menuAnimations, setMenuAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        right: -50,
        opacity: 0
    }));
    const [menuDrawerContentAnimations, setMenuDrawerContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: 0
    }));
    const [menuDrawerAnimations, setMenuDrawerAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: window.innerWidth
    }));
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [preflight, setPreflight] = useState(false);
    const [selectedNavigationItem, setSelectedNavigationItem] = useState(false);
    const [nonce, setNonce] = useState(moment().unix());
    const [sheet, setSheet] = useState(null);
    const [size, setSize] = useState({ height: window.innerHeight, width: window.innerWidth });
    const [theme, setTheme] = useState('light');
    const [user, setUser] = useState(null);

    const onAppClick = async app => {
        try {

            // close navigation drawer
            setMenuExpanded(false);

            // prepare url for selected app
            let key = isIOS && 'ios' || isAndroid && 'android' || 'web';
            let url = typeof(app.url) === 'string' ? app.url : app.url[key];

            // determine if single sign on is supported by the requested app and device type
            if(app.key === 'peak_training' || (key === 'web' && ['aft','global_data'].includes(app.key) === true)) {

                // request a single-use login token for single sign on
                let { token } = await Request.aft.get(utils, '/users/', {
                    type: 'new_single_use_login_token',
                    user_id: userRef.current.user_id
                });

                // add token to url as query parameter
                url = `${url}/?route=single_sign_on&token=${encodeURIComponent(token)}`;
            }

            // open page for url if a device match has been found or if only one option is available
            if(url) {
                window.location.href = url;
                return;
            }

            // prepare friendly representation of the current user device
            let device = getDeviceTypeText(key);

            // prepare friendly list of supported platforms
            let platforms = Object.keys(app.url).map(getDeviceTypeText);

            // show alert to user and ask for routing preference
            utils.alert.show({
                title: app.title,
                message: `${app.title} is not available on ${device} but is available for ${Utils.oxfordImplode(platforms)}. Where can we direct you?`,
                buttons: [{
                    key: 'ios',
                    title: 'Apple App Store',
                    style: 'default',
                    visible: app.url.ios ? true : false
                },{
                    key: 'android',
                    title: 'Google Play Store',
                    style: 'default',
                    visible: app.url.android ? true : false
                },{
                    key: 'web',
                    title: 'Website',
                    style: 'default',
                    visible: app.url.web ? true : false
                },{
                    key: 'cancel',
                    title: 'Close',
                    style: 'cancel'
                }],
                onClick: key => {
                    let url = app.url[key];
                    if(url) {
                        window.open(url);
                    }
                }
            });
            
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the auto login process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onContactClick = () => {
        setMenuExpanded(false);
        utils.layer.open({
            id: 'support_contact_form',
            Component: SupportContantForm
        });
    }

    const onCloseLayer = layerID => {

        // filter layer id out of layer indexes
        setLayerIndex(layerIndex => {
            return update(layerIndex, {
                $apply: ids => ids.filter(id => id !== layerID)
            });
        });

        setLayers(layers => {

            // update layer and set visible flag to false
            let next = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });

            // determine if at least one layer is still visible
            let targets = next.filter(layer => layer.visible !== false);
            if(targets.length === 0) {
                console.log('layers reset');
                document.body.style.overflowY = 'scroll';
                return [];
            }
            return next;
        });
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => {
            return update(layers, {
                [index]: {
                    position: {
                        $set: position
                    }
                }
            });
        });
    }

    const onLogin = async ({ user }) => {
        try {

            // set user with ref first so we don't have to wait for a state updated to access
            userRef.current = user;
            setUser(user);

            // set active dealership with ref first so we don't have to wait for a state updated to access
            dealershipRef.current = user.dealership;
            setDealership(dealershipRef.current);

            // set default active values
            setActive({
                view: 'health',
                subView: null
            });

            // amimate components into view, lol
            setBadgeAnimations({ opacity: 1, transform: 'scale(1)' });
            setContentAnimations({ opacity: 1, top: 0 });
            setLogoAnimations({ left: 0, opacity: 1 });
            setMenuAnimations({ opacity: 1, right: 0 });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLoginToAFT = async props => {
        try {

            // send request to server
            let { user } = await Request.aft.post(utils, '/users/', {
                ...props,
                type: 'login'
            });

            console.log(user);

            // update local state with user object
            setUser(user);

            // write refresh token to local storage
            localStorage.setItem('aft_refresh_token', user.refresh_token);

        } catch(e) {

            // determine if a refresh token was provided, error alert is only needed for traditional logins
            if(!props.refresh_token) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
                });
            }
        }
    }

    const onLogoutClick = () => {
        utils.alert.show({
            title: 'Logout',
            message: `Are you sure that you want to logout of your account?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    
                    // amimate components out of view
                    setBadgeAnimations({ opacity: 0, transform: 'scale(0.5)' });
                    setContentAnimations({ opacity: 0, top: 0 });
                    setLogoAnimations({ left: 0, opacity: -50 });
                    setMenuAnimations({ opacity: 0, right: -50 });

                    // remove auto login variables from graci preferences cookie
                    let preferences = utils.graci.preferences.get();
                    preferences.auto_login = false;
                    preferences.last_login = null;
                    preferences.refresh_token = null;
                    utils.graci.preferences.set(preferences);

                    // remove user from local state after brief timeout
                    setTimeout(setUser.bind(this, null), 500);
                    return;
                }
            }
        });
    }

    const onMouseEnterMainContent = () => {

        // deselect currently selected navigation item some menu will hide
        setSelectedNavigationItem(false);
    }

    const onOpenLayer = nextLayer => {

        if(layers.find(layer => {
            return layer.id === nextLayer.id && layer.visible !== false
        })) {
            utils.alert.show({
                title: nextLayer.abstract ? nextLayer.abstract.getTitle() : 'Just a Second',
                message: `There is already a window open for ${nextLayer.abstract ? `"${nextLayer.abstract.getTitle()}"` : 'this information'}`
            });
            return;
        }

        setTimeout(() => {
            setLayers(layers => update(layers, {
                $push: [nextLayer]
            }))
            setLayerIndex(layerIndex => update(layerIndex, {
                $unshift: [nextLayer.id]
            }))
        }, 0)
    }

    const onSelectedNavigationItemChange = () => {
        if(selectedNavigationItem === null) {
            setMenuDrawerContentAnimations({ left: 0 });
            return;
        }
        if(menuExpanded === true) {
            setMenuDrawerContentAnimations({ left: -size.width });
        }
        if(menuExpanded === false) {
            setSelectedNavigationItem(null);
        }
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers, update(layers, {
            $apply: layers => layers.map(layer => {
                layer.moveToFront = layer.id === layerID;
                return layer;
            })
        }))

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex, update(layerIndex, {
            $splice: [
                [_index, 1],
                [0, 0, layerID]
            ]
        }))
    }

    const onSetStyleSheetProperties = () => {
        setTheme(window.theme);
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
        document.querySelector('meta[name="theme-color"]').setAttribute("content", Appearance.colors.layerBackground());
    }

    const onSizeChange = () => {
        console.log('on size change');
        setSize({ height: window.innerHeight, width: window.innerWidth });
    }

    const onToggleMobileMenu = () => {
        setMenuExpanded(val => !val);
        setSelectedNavigationItem(null);
    }

    const onShowLoginWindow = () => {

        // declare variables for login process
        let password = null;
        let username = null;

        // show alert requesting user login credentials
        utils.alert.show({
            title: 'Login To Your Dealership',
            message: 'Enter your username and password below to login to your dealership. This is the same login that you use for Applied Fire Technologies, Global Data, and all other Graci products.',
            content: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 12,
                    width: '100%'
                }}> 
                    <TextField
                    icon={'user'}
                    autoCapitalize={'none'}
                    placeholder={'Username'}
                    useDelay={false}
                    onChange={text => username = text}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: '500',
                    }}/>
                    <TextField
                    icon={'lock'}
                    isSecure={true}
                    placeholder={'Password'}
                    useDelay={false}
                    onChange={text => password = text}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: 500
                    }}
                    containerStyle={{
                        marginTop: 8,
                        paddingRight: 6,
                        width: '100%'
                    }} 
                    appendContent={(
                        <img
                        className={'image-button'}
                        onClick={window.open.bind(this, `${API.aft.server}/reset-password/index.html`)}
                        src={'images/help-button-grey.png'}
                        style={{
                            borderRadius: 10,
                            height: 20,
                            marginLeft: 8,
                            objectFit: 'contain',
                            width: 20
                        }}/>
                    )}/>
                </div>
            ),
            buttons: [{
                key: 'login',
                title: 'Login',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'login' && password && username) {
                    setTimeout(onLoginToAFT.bind(this, { password, username }), 500);
                    return;
                }
            }
        });
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const getApps = () => {
        return [{
            icon: 'images/aft-mobile-icon.jpg',
            key: 'aft',
            title: 'Applied Fire Technologies',
            url: {
                android: 'https://play.google.com/store/apps/details?id=com.aftplatform.aft&hl=en_US&gl=US',
                ios: 'https://apps.apple.com/us/app/applied-fire-technologies/id1394113289',
                web: 'https://aftplatform.com'
            }
        },{
            icon: 'images/back-office-icon.jpg',
            key: 'back_office',
            title: 'Back Office',
            url: 'https://extranet.securefreedom.com/GlobalHealthSafety/Dashboard.aspx'
        },{
            icon: 'images/dreamcatcher-mobile-icon.jpg',
            key: 'dreamcatcher',
            title: 'DreamCatcher',
            url: {
                android: 'https://play.google.com/store/apps/details?id=com.ghscompanies.dreamcatcher&hl=en_US&gl=US',
                ios: 'https://apps.apple.com/nz/app/dreamcatcher-by-ghs/id1422553229'
            }
        },{
            icon: 'images/flipchart-mobile-icon.jpg',
            key: 'flipchart',
            title: 'FlipChart',
            url: 'https://apps.apple.com/us/app/flipchart-by-ghs/id1438168423'
        },{
            icon: 'images/global-data-mobile-icon.jpg',
            key: 'global_data',
            title: 'Global Data',
            url: {
                android: 'https://play.google.com/store/apps/details?id=com.ghs.globaldata&hl=en_US&gl=US',
                ios: 'https://apps.apple.com/us/app/global-data-by-ghs/id1554941899',
                web: 'https://globaldata.link'
            }
        },{
            icon: 'images/omnishield-mobile-icon.jpg',
            key: 'omnishield',
            title: 'OmniShield',
            url: {
                android: 'https://play.google.com/store/apps/details?id=com.commlink.omnishield2&hl=en_US&gl=US',
                ios: 'https://apps.apple.com/us/app/omnishield/id1491369651'
            }
        },{
            icon: 'images/peak-training-icon.jpg',
            key: 'peak_training',
            title: 'Peak Training',
            url: 'https://globalpeaktraining.com'
        }];
    }

    const getAppNavigationComponents = () => {
        let components = getApps().map((app, index, apps) => {
            return (
                <div 
                className={'text-button'}
                key={index}
                onClick={onAppClick.bind(this, app)}
                style={{
                    alignItems: 'center',
                    borderBottom: index !== apps.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 12,
                    width: '100%'
                }}>
                    <img 
                    src={app.icon}
                    style={{
                        borderRadius: 8,
                        height: 30,
                        marginRight: 12,
                        width: 30
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        flexGrow: 1,
                        paddingRight: 36
                    }}>{app.title}</span>
                    <img
                    src={'images/next-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain',
                        marginLeft: 8,
                        opacity: 0.75
                    }} />
                </div>
            )  
        });
        return getNavigationContainer(components);
    }

    const getDesktopVersionDetails = () => {
        return Utils.isMobile() === false && (
            <div style={{
                paddingTop: 24,
                paddingBottom: 128,
                textAlign: 'center'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    display: 'block'
                }}>{`Version ${API.app_version} Build ${API.build}`}</span>
            </div>
        )
    }

    const getDeviceTypeText = key => {
        switch(key) {
            case 'android':
            return 'Android mobile devices';

            case 'ios':
            return 'iOS mobile devices';

            default:
            return 'the web';
        }
    }

    const getLayers = () => {
        return layers.map(({ id, abstract, options, title, visible, Component }, index) => {

            if(visible === false) {
                // must return null instead of using filter
                // using filter for visible does not preseve other visible layers
                return null;
            }
            return (
                <Component
                key={index}
                title={title}
                index={index}
                utils={utils}
                abstract={abstract}
                options={{
                    ...options,
                    index: index,
                    onClose: onCloseLayer,
                    onReposition: onLayerReposition,
                    onSetLayerIndex: onSetLayerIndex,
                    zIndex: EndIndex - layerIndex.findIndex(indexID => id === indexID)
                }}/>
            )
        });
    }

    const getHeader = () => {

        // prepare naivgation items
        let items = [{
            key: 'apps',
            title: 'Apps'
        },{
            key: 'contact',
            onClick: onContactClick,
            title: 'Contact'
        },{
            key: 'pricing',
            onClick: setActive.bind(this, {view: 'pricing'}),
            title: 'Pricing'
        },{
            key: 'privacy',
            title: 'Privacy'
        },{
            key: 'health',
            onClick: setActive.bind(this, {view: 'health'}),
            title: 'Service Health'
        }];

        // determine if current device is a mobile device
        if(Utils.isMobile() === true) {
            return (
                <>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: Appearance.colors.layerBackground(),
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 12,
                    padding: 20,
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <animated.div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        paddingLeft: 8,
                        position: 'relative',
                        ...logoAnimations
                    }}>
                        <img 
                        src={theme === 'dark' ? 'images/logo-text-white.png' : 'images/logo-text-grey.png'}
                        style={{
                            height: 20,
                            width: 'auto'
                        }} />
                    </animated.div>
                    <animated.img 
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/navigation-icon-${theme}.png`}
                    style={{
                        height: 30,
                        objectFit: 'contain',
                        position: 'relative',
                        width: 30,
                        ...menuAnimations
                    }} />
                </div>
                <animated.div style={{
                    backgroundColor: Appearance.colors.layerBackground(),
                    bottom: 0,
                    height: '100%',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 5500,
                    ...menuDrawerAnimations
                }}>
                    <animated.div style={{
                         alignItems: 'center',
                         display: 'flex',
                         flexDirection: 'row',
                         height: '100dvh',
                         overflow: 'hidden',
                         position: 'relative',
                         width: size.width * 2,
                         ...menuDrawerContentAnimations
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            width: size.width
                        }}>
                            {items.map((item, index) => (
                                <span 
                                className={'text-button'}
                                key={index}
                                onClick={item.onClick || setSelectedNavigationItem.bind(this, item.key)}
                                style={{
                                    ...Appearance.textStyles.layerItemTitle(),
                                    fontSize: 24,
                                    ...item.key === active.view && {
                                        color: Appearance.colors.primary()
                                    }
                                }}>{item.title}</span>
                            ))}
                        </div>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            position: 'relative',
                            width: size.width
                        }}>
                            {getInnerDrawerComponents()}
                            <img
                            className={'text-button'}
                            onClick={setSelectedNavigationItem.bind(this, null)}
                            src={`images/back-arrow-${theme}.png`}
                            style={{
                                height: 25,
                                objectFit: 'contain',
                                position: 'absolute',
                                left: 20,
                                top: 20,
                                width: 18
                            }} />
                        </div>
                    </animated.div>
                    <img
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/close-button-${theme}.png`}
                    style={{
                        height: 25,
                        objectFit: 'contain',
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        width: 25
                    }} />
                    <div style={{
                        bottom: 0,
                        padding: 24,
                        position: 'absolute',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                </animated.div>
                </>
            )
        }
        
        // fallback to rendering content for tablets and desktop 
        return (
            <div style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.layerBackground(),
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 12,
                padding: '10px 10px 10px 20px',
                textAlign: 'center',
                width: '100vw'
            }}>
                <animated.div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingLeft: 8,
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...logoAnimations
                }}>
                    <img 
                    src={theme === 'dark' ? 'images/logo-text-white.png' : 'images/logo-text-grey.png'}
                    style={{
                        height: 20,
                        width: 'auto'
                    }} />
                </animated.div>
                <animated.img 
                src={'images/logo.png'}
                style={{
                    height: 40,
                    objectFit: 'contain',
                    width: 40,
                    ...badgeAnimations,
                    ...size.width < 1300 && {
                        opacity: 0
                    }
                }} />
                <animated.div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...menuAnimations
                }}>
                    {items.map((item, index) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: 32,
                                position: 'relative'
                            }}>
                                <span 
                                className={'text-button'}
                                onClick={item.onClick}
                                onMouseEnter={setSelectedNavigationItem.bind(this, item.key)}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    ...item.key === active.view && {
                                        color: Appearance.colors.primary()
                                    }
                                }}>{item.title}</span>
                                {selectedNavigationItem === item.key && getNavigationComponents(item.key)}
                            </div>
                        )
                    })}
                    {getProfileContent()}
                </animated.div>
            </div>
        )
    }

    const getInnerDrawerComponents = () => {
        switch(selectedNavigationItem) {
            case 'apps':
            return getApps().map((app, index) => {
                return (
                    <span 
                    className={'text-button'}
                    key={index}
                    onClick={onAppClick.bind(this, app)}
                    style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        fontSize: 24
                    }}>{app.title}</span>
                )
            });

            case 'privacy':
            return getPrivacyItems().map((item, index) => {
                return (
                    <span 
                    className={'text-button'}
                    key={index}
                    onClick={window.open.bind(this, item.url)}
                    style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        fontSize: 24
                    }}>{item.title}</span>
                )
            });

            default:
            return null;
        }
    }

    const getMainContent = () => {

        // no additional logic is required if preflight tasks have not been completed
        if(preflight === false) {
            return null;
        }

        // show login screen if a user has not already logged in
        if(!user) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100dvh',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <Login 
                    onLogin={onLogin} 
                    utils={utils} />
                </div>
            )
        }

        // determine which panels to show for a user that is already logged in
        let targets = panels[active.view] || [];
        return (
            <animated.div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                ...contentAnimations
            }}>
                {getHeader()}
                {getLayers()}
                <div 
                onMouseEnter={onMouseEnterMainContent}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    width: '100%'
                }}>
                    <div
                    className={'row p-0 m-0'}
                    style={{
                        maxWidth: 1250,
                        position: 'relative',
                        width: '100%'
                    }}>
                        {targets.map(({ Component }, index) => (
                            <Component
                            index={index} 
                            key={index}
                            utils={utils}/>
                        ))}
                    </div>
                </div>
            </animated.div>
        )
    }

    const getNavigationComponents = () => {
        switch(selectedNavigationItem) {
            case 'apps':
            return getAppNavigationComponents();

            case 'privacy':
            return getPrivacyNavigationComponents();

            default:
            return null;
        }
    }

    const getNavigationContainer = (components, lastItem) => {
        return (
            <div 
            onMouseLeave={setSelectedNavigationItem.bind(this, false)}
            style={{
                alignItems: lastItem ? 'flex-end' : 'center',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: 40,
                right: lastItem ? 0 : null,
                zIndex: 7500
            }}>
                <img 
                src={theme === 'dark' ? 'images/floating-menu-carrot-grey.png' : 'images/floating-menu-carrot-white.png'}
                style={{
                    height: 25,
                    objectFit: 'contain',
                    position: 'relative',
                    top: 8,
                    right: lastItem ? 12 : null,
                    width: 25
                }} />
                <div style={{
                    background: Appearance.colors.layerBackground(),
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 12,
                    boxShadow: '5px 5px 20px rgba(0,0,0,0.05)',
                    textAlign: 'left'
                }}>
                    {components}
                </div>
            </div>
        )
    }

    const getPrivacyItems = () => {
        return [{
            key: 'aft',
            title: 'Applied Fire Technologies',
            url: 'https://aftplatform.com/privacy'
        },{
            key: 'global_data',
            title: 'Global Data',
            url: 'https://globaldata.link/privacy'
        },{
            key: 'omnishield',
            title: 'OmniShield',
            url: 'https://www.homesafenetwork.com/privacypolicy/'
        }];
    }

    const getPrivacyNavigationComponents = () => {

        let components = getPrivacyItems().map((item, index, items) => {
            return (
                <div 
                className={'text-button'}
                key={index}
                onClick={window.open.bind(this, item.url)}
                style={{
                    alignItems: 'center',
                    borderBottom: index !== items.length - 1 && `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 12,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        flexGrow: 1,
                        paddingRight: 36
                    }}>{item.title}</span>
                    <img
                    src={'images/next-arrow-grey-small.png'}
                    style={{
                        width: 12,
                        height: 12,
                        objectFit: 'contain',
                        marginLeft: 8,
                        opacity: 0.75
                    }} />
                </div>
            )  
        });
        return getNavigationContainer(components);
    }

    const getProfileContent = () => {
        return (
            <div style={{
                alignItems: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}>
                <div 
                className={'text-button'}
                onClick={onProfileClick}
                ref={profileRef}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '6px 6px 6px 14px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginRight: 8
                    }}>{user.full_name}</span>
                    <img 
                    src={user.avatar || 'images/avatar-placeholder.png'}
                    style={{
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: 15,
                        height: 30,
                        minWidth: 30,
                        objectFit: 'cover',
                        width: 30
                    }} />
                </div>
            </div>
        )
    }

    const onProfileClick = () => {

        // show sheet with profile options
        utils.sheet.show({
            items: [{
                key: 'logout',
                title: 'Logout',
                style: 'default'
            }],
            position: 'bottom',
            target: profileRef.current
        }, key => {
            switch(key) {
                case 'logout':
                onLogoutClick();
                break;
            }
        });
    }

    const runPreflightTasks = async () => {
        try {

            // fetch and decode cross-platform cookie
            let preferences = utils.graci.preferences.get();

            // determine if a token is present and auto login is enabled for the platform
            if(preferences.refresh_token && preferences.auto_login === true) {

                // set loading flag and run login for access token
                await utils.loader.show();
                let result = await runLogin(utils, { refresh_token: preferences.refresh_token });

                // set user manually so login ui doesn't render after preflight flag is updated
                setUser(result.user);
                
                // run post-login logic
                onLogin(result);
            }

            // set preflight flag as complete
            setPreflight(true);

        } catch(e) {
            console.error(e.message);
            setPreflight(true);
            utils.loader.hide();
        }
    }

    const utils = {
        alert: {
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    }))
                } catch(e) {
                    console.log(e.message)
                }
            },
            dev: async () => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.log(e.message)
                }
            },
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-API': `Version ${API.version}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.build}`,
                    ...userRef.current && {
                        'Authorization': `Bearer ${userRef.current.token}`,
                        'Identification': `User ${userRef.current.user_id}`
                    }
                }
            }
        },
        datePicker: {
            show: props => setDatePicker({
                utils: utils,
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            })
        },
        events: EventsManager,
        graci: {
            preferences: {
                get: () => {
                    let result = Cookies.get('auto_login_preferences');
                    return typeof(result) === 'string' ? JSON.parse(result) : {};
                },
                set: props => {
                    Cookies.set('auto_login_preferences', JSON.stringify(props));
                }
            }
        },
        layer: {
            open: layer => onOpenLayer(layer),
            close: layer => onCloseLayer(layer),
            requestClose: id => {
                let closeEvent = new CustomEvent('onLayerAction', {
                    detail: {
                        action: 'close',
                        layerID: id
                    }
                });
                window.dispatchEvent(closeEvent);
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                });
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                });
            }
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({ ...sheet, onClick: callback })
            }
        }
    }

    useEffect(() => {
        if(Utils.isMobile() === true) {
            setMenuExpanded(false);
        }
    }, [active]);

    useEffect(() => {
        dealershipRef.current = dealership;
    }, [dealership]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {
        if(Utils.isMobile() === false) {
            setMenuDrawerAnimations({ left: size.width });
            return;
        }
        setMenuDrawerAnimations({ left: menuExpanded ? 0 : size.width });
    }, [menuExpanded, size]);

    useEffect(() => {
        if(Utils.isMobile() === true) {
            onSelectedNavigationItemChange();
        }
    }, [selectedNavigationItem]);

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {

        runPreflightTasks();

        // setup window listeners
        window.addEventListener('resize', onSizeChange);
        window.addEventListener('beforeunload', evt => {
            evt.returnValue = null;
            return null;
        });

        // css variables
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

    }, []);

    return (
        <div
        className={`root-container ${theme}`}
        nonce={nonce}
        style={{
            height: size.height,
            position: 'relative',
            width: size.width
        }}>
            {getMainContent()}
            {user && getDesktopVersionDetails()}
            {datePicker && (
                <DatePicker
                {...datePicker}
                onClose={() => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={() => setSheet(null)}/>
            )}
            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert
                    {...alert}
                    key={index}
                    utils={utils}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} />
                ))}
            </AlertStack>
        </div>
    )
}

export default App;
